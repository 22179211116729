import { Application } from '@pixi/app'
import {BatchRenderer, Renderer} from "@pixi/core";
import {ParticleRenderer} from "@pixi/particle-container";
import {TickerPlugin} from "@pixi/ticker";
import {settings} from "@pixi/settings";
import {SCALE_MODES} from "@pixi/constants";
import {Graphics} from "@pixi/graphics";
import {Sprite} from "@pixi/sprite";

Renderer.registerPlugin('batch', BatchRenderer);
Application.registerPlugin(TickerPlugin);
Renderer.registerPlugin('particle', ParticleRenderer);

const canvasSize = 512;
const pixelSize = 16;

export const Draw = (element) => {
    let size = 1;
    const _pixiApp = new Application({
        width: canvasSize * size,
        height: canvasSize * size,
        autoResize: true,
        backgroundAlpha: 0,
    });

    _pixiApp.stage.sortableChildren = true;

    var ratOverlay = Sprite.from('https://api.cryptor.at/nightmares/avatar/0');
    _pixiApp.stage.addChild(ratOverlay);
    ratOverlay.alpha = 0;
    ratOverlay.zIndex = 2;
    ratOverlay.width = 512;
    ratOverlay.height = 512;

    _pixiApp.pixels = {};
    _pixiApp.setColor = function (c) {
        this.color = Number(c.replace('#', '0x'));
    }
    _pixiApp.getPixels = function () {
        return this.pixels;
    }
    settings.SCALE_MODE = SCALE_MODES.LINEAR;
    element.appendChild(_pixiApp.view);

    _pixiApp.toggleOverlay = function() {
        if(ratOverlay.alpha > 0) {
            ratOverlay.alpha = 0;
            return;
        }
        ratOverlay.alpha = 0.8;
    }

    _pixiApp.setPixel = function(x, y, color) {
        let graphics = new Graphics();
        graphics.beginFill(color);
        graphics.zIndex = 1;
        graphics.drawRect(x, y, pixelSize, pixelSize);
        _pixiApp.stage.addChild(graphics);
    }

    _pixiApp.getRealPixel = function(x) {
        return Math.floor(x / pixelSize);
    };

    _pixiApp.getPixel = function(x) {
        return _pixiApp.getRealPixel(x) * pixelSize;
    };

    let drawing = false;
    let drawPixel = function(e) {
        let clickedX = e.clientX - _pixiApp.view.getBoundingClientRect().left;
        let clickedY = e.clientY - _pixiApp.view.getBoundingClientRect().top;

        let x = _pixiApp.getRealPixel(clickedX);
        let y = _pixiApp.getRealPixel(clickedY);
        if(x < 0 || y < 0 || x >= 32 || y >= 32) {
            return;
        }
        _pixiApp.pixels[x + ',' + y] = _pixiApp.color;
        _pixiApp.setPixel(
            _pixiApp.getPixel(clickedX),
            _pixiApp.getPixel(clickedY),
            _pixiApp.color
        );
    }

    _pixiApp.preloadCanvas = function(url) {
        try {
            fetch(url).then((content) => {
                return content.json();
            }).then((data) => {
                for (var i in data) {
                    _pixiApp.pixels[data[i].x + ',' + data[i].y] = data[i].color;
                    _pixiApp.setPixel(
                        data[i].x * pixelSize,
                        data[i].y * pixelSize,
                        data[i].color
                    );
                }
            });
        } catch(e) {
            //
        }
    }

    /** Interaction **/
    window.onmousemove = function(e) {
        if(!drawing) {
            return;
        }
        drawPixel(e);
    };
    window.onmouseup = function() {
        drawing = false;
    };
    window.onmousedown = function(e) {
        drawing = true;
        drawPixel(e)
    };

    const updateAnimation = () => {
        _pixiApp.render();
    }
    _pixiApp.ticker.add(updateAnimation);

    return _pixiApp;
};