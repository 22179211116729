<template>
  <section class="header-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">Make-a-painting</h2>
        </div>
      </div>
    </div>
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <div id="draw-canvas"></div>
          <span class="text-white">Brush color</span><br/>
          <input type="color" id="color" v-model="color" @input="setColor">
        </div>
      </div>
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-12 text-center">
          <input type="button" class="btn btn-secondary" @click="toggleOverlay" value="Toggle Rat overlay" /> &nbsp;
          <input type="button" class="btn btn-secondary" @click="clear" value="Clear" /> &nbsp;
          <input type="button" class="btn btn-primary" @click="submit" value="Save my masterpiece! :D" />
        </div>
        <div class="col-md-12 text-center text-white">
          <h3>{{message}}</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Draw} from "../Draw";
import {ethers} from "ethers";

export default {
  data() {
    return {
      instance: null,
      color: '#AA0000',
      message: '',
      verified: false,
    }
  },
  mounted() {
    this.instance = Draw(
        document.getElementById('draw-canvas')
    );
    this.instance.setColor(this.color);
    this.instance.preloadCanvas(this.api_url + '/nightmares/canvasdata/' + this.$route.params.id);
  },
  methods: {
    setColor(e) {
      this.instance.setColor(e.target.value);
    },
    toggleOverlay() {
      this.instance.toggleOverlay();
    },
    clear() {
      document.getElementById('draw-canvas').innerHTML = '';
      this.instance = Draw(
          document.getElementById('draw-canvas')
      );
      this.instance.setColor(this.color);
    },
    submit() {
      let data = {
        traitId: this.$route.params.id,
        owner: this.$store.state.address,
        pixels: this.instance.getPixels()
      };
      let messageHash = ethers.utils.sha256(
          ethers.utils.toUtf8Bytes('ratPainting-'+ data.owner +'-'+ data.traitId)
      );
      this.$store.state.provider.getSigner().signMessage(messageHash).then(signature => {
        data.signature = signature;
        data.msgHash = messageHash;
        fetch(this.api_url + '/nightmares/canvas', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: 'data=' + JSON.stringify(data)
        }).then(() => {
          this.message = "Drawing stored!";
        });
      });
    }
  }
}
</script>

<style>
#draw-canvas canvas {
  border: 5px solid black;
  border-radius: 5px;
  background-image: url("/sprites/summer/bg-canvas.png") ;
  background-size: contain;
}
</style>